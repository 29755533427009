import React from 'react';
import styled from 'styled-components';

import { Link } from '../../i18n';

const LogoImg = styled.img`
  cursor: pointer;
  height: 52px;

  @media screen and (max-width: 768px) {
    height: auto;
    width: 120px;
  }
`;

export const Logo: React.FC = () => (
  <Link href="/">
    <LogoImg src="/static/img/logo/smartpost.svg" alt="Smartpost" />
  </Link>
);
