import React from 'react';

import { Link, useTranslation } from '../../i18n';
import { Layout } from '../element';
import { ElementLink } from '../anchor/link';

import { FooterSectionProps } from './types';

import { FooterSectionList } from './styled';

import { footerMenu } from './links';

export const FooterSection: React.FC<FooterSectionProps> = ({ title, links }) => {
  const [t] = useTranslation('common');

  return (
    <FooterSectionList>
      <li>{t(title)}</li>

      {links.map(({ type = 'router', ...link }, idx) => (
        <li key={idx.toString()}>
          {type === 'router' && (
            <Link href={link.href}>
              <a>{t(link.translate)}</a>
            </Link>
          )}
          {type === 'mail' && (
            <ElementLink href={`mailto:${link.href}`} type="primary" underline>
              {link.translate}
            </ElementLink>
          )}
        </li>
      ))}
    </FooterSectionList>
  );
};

export const Info: React.FC = () => {
  const [t] = useTranslation('common');

  return (
    <Layout.Row gutter={20}>
      {footerMenu.map((menu) => (
        <Layout.Col key={menu.title} span={24} xs={24} sm={24} md={24} lg={5}>
          <FooterSection title={menu.title} links={menu.links} />
        </Layout.Col>
      ))}

      <Layout.Col span={24} xs={24} sm={24} md={24} lg={4}>
        <FooterSectionList>
          <li>{t('footer.social')}</li>
          <li>
            <a
              className="social"
              href="https://www.facebook.com/smartpost.global"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="/static/img/social/fb.svg" alt="smartpost.global" width={30} />
            </a>

            <a className="social" href="https://twitter.com/SMARTPostGlobal" target="_blank" rel="noreferrer noopener">
              <img src="/static/img/social/tw.svg" alt="SMARTPostGlobal" width={30} />
            </a>
          </li>
        </FooterSectionList>
      </Layout.Col>
    </Layout.Row>
  );
};
