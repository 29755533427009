import styled from 'styled-components';

export const NavBar = styled.div<{ pinned?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  font-size: 16px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2000;

  will-change: transform;
  transition: transform 0.2s linear;

  ${(props) => props.pinned && `transform: translateY(0%);`}
  ${(props) => !props.pinned && `transform: translateY(-100%);`}
`;

export const HeaderNav = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.sizes.header.height};
  margin: 0;
  padding: 0;
  white-space: nowrap;
`;

export const NavLi = styled.li`
  margin-left: 27px;
  margin-right: 27px;
  display: inline;
  list-style: none;

  & .el-dropdown {
    cursor: pointer;
    color: rgb(41, 41, 41);

    & .el-dropdown-link {
      font-size: 16px;
      padding-bottom: 4px;
    }
  }
`;

export const NavLink = styled.a`
  display: inline-block;
  position: relative;
  line-height: 1;
  color: #292929;
  padding-bottom: 4px;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
  &::before {
    display: block;
    position: absolute;
    content: '';
    height: 1px;
    width: 0;
    background-color: #292929;
    transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
    left: 50%;
    bottom: 0;
  }
  &::after {
    display: block;
    position: absolute;
    content: '';
    height: 1px;
    width: 0;
    background-color: #292929;
    transition: width 0.3s ease-in-out;
    left: 50%;
    bottom: 0;
  }
  &:hover::before {
    width: 50%;
    left: 0;
  }
  &:hover::after {
    width: 50%;
  }
  &:hover {
    color: #999;
  }
`;

export const MobileMenu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 100%;
  margin: 0;
  padding: 0;
  border-top: 1px solid #ccc;

  & > li {
    width: 100%;
    text-align: center;
    display: block;
    list-style: none;
  }

  & > li > a {
    display: block;
    position: relative;
    line-height: 1;
    color: #292929;
    padding: 20px 0;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }

  & > li > a:before,
  & > li > a:after {
    content: none;
  }

  & > li > .el-dropdown {
    padding: 20px 0;
    color: #292929;
  }

  & > li > .el-dropdown .el-dropdown-link {
    font-size: 20px;
    padding-bottom: 4px;
  }

  & > li > .el-dropdown .el-dropdown-menu {
    min-width: 225px;
    text-align: left;
  }
`;

export const MobileLogoContainer = styled.div`
  height: ${({ theme }) => theme.sizes.header.height};

  display: flex;
  justify-content: center;
  align-items: center;
`;
