import React from 'react';
import Head from 'next/head';

import { SEOMetaTags } from '../components/seo';
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import { LoadingIndicator } from '../components/loading-indicator';
import { NotifyBanner } from '../components/notifications/banner';

interface AppLayoutProps {
  title?: string;
}

export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  title = 'SMART Post Global – доставка посылок из Европы',
}) => (
  <>
    <Head>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />

      <link rel="shortcut icon" type="image/x-icon" href="/static/favicon.ico" />
      <link rel="manifest" href="/static/manifest.json" />

      <link
        href="https://fonts.googleapis.com/css?family=Lato:300,400,900&subset=latin,cyrillic"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700&subset=latin,cyrillic"
        rel="stylesheet"
        type="text/css"
      />

      <SEOMetaTags />
    </Head>

    <>
      <Header />
      <div className="view">{children}</div>
      <Footer />

      <NotifyBanner />

      <LoadingIndicator />
    </>
  </>
);
