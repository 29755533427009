import React, { useCallback, useState } from 'react';

import { Router, useTranslation } from '../../i18n';
import { Dropdown } from '../element';
import { Active, Item } from './styled';

import { getLanguage, langList } from '../../config/languages';

const renderItem = (item: typeof langList[0]) => (
  <Item key={item.code} command={item.code}>
    <img src={item.flag} alt={item.code} /> {String(item.code).toUpperCase()}
  </Item>
);

export const Switcher: React.FC<{ mobile?: boolean }> = ({ mobile = false }) => {
  const [, i18n] = useTranslation();
  const [currentLang, setLang] = useState(getLanguage(i18n.language));

  const handleChange = useCallback(
    (code: string) => {
      if (code !== currentLang.code) {
        i18n.changeLanguage(code, () => {
          setLang(getLanguage(code));

          const [, lang, ...pathParts] = Router.asPath.split('/');

          if (lang !== code) {
            const newPath = `/${code}/${pathParts.join('/')}`;

            // correct URL
            Router.replace(newPath);
          }
        });
      }
    },
    [currentLang, setLang]
  );

  const menu = <Dropdown.Menu>{langList.map(renderItem)}</Dropdown.Menu>;

  return (
    <Dropdown trigger="click" menu={menu} onCommand={handleChange}>
      <Active>
        <img src={currentLang.flag} alt={currentLang.code} />
        {!mobile && String(currentLang.code).toUpperCase()}
        <i className="el-icon-arrow-down el-icon--right" />
      </Active>
    </Dropdown>
  );
};
