import styled from 'styled-components';
import React from 'react';

export const H1 = styled.h1`
  font-size: 36px;
  font-weight: normal;
  line-height: 43px;

  margin: 0;
  padding: 0 0 17px 0;
`;

export const H2 = styled.h2`
  font-size: 21px;
  font-weight: normal;
  line-height: 28px;

  margin: 0;
  padding: 0 0 6px 0;
`;

export const H3 = styled.h2`
  font-size: 18px;
  font-weight: normal;
  line-height: 18px;

  margin: 0;
  padding: 0 0 6px 0;
`;

export const Text: React.FC<{ txt: string }> = ({ txt }) => (
  // eslint-disable-next-line react/no-danger
  <span dangerouslySetInnerHTML={{ __html: txt }} />
);

export const Subtitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
`;

export const FormLabel = styled.div`
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 7px;

  & > img {
    width: 20px;
  }
`;
