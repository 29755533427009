import React from 'react';
import { WithTranslation } from 'next-i18next';

import { DropDown, DropDownProps } from '../dropdown';

import { Link, withTranslation } from '../../i18n';
import { NavLi, NavLink } from './styled';

export interface NavItemProps {
  translate: string;
  href?: string;
  dropdown?: DropDownProps;
}

export const NavElement: React.FC<NavItemProps & WithTranslation> = ({ t, translate, href, dropdown }) => (
  <NavLi>
    <>
      {href && (
        <Link href={href}>
          <NavLink>{t(translate)}</NavLink>
        </Link>
      )}

      {dropdown && <DropDown title={translate} list={dropdown.list} t={t} />}
    </>
  </NavLi>
);

export const NavItem = withTranslation('common')(NavElement);
