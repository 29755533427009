import { FooterSectionProps } from './types';

export const footerMenu: FooterSectionProps[] = [
  {
    title: 'footer.support.title',
    links: [
      {
        href: '/kb',
        translate: 'footer.support.knowledgeBase',
      },
      {
        href: 'support@smartpost.global',
        type: 'mail',
        translate: 'support@smartpost.global',
      },
    ],
  },
  {
    title: 'footer.information.title',
    links: [
      {
        href: '/rates',
        translate: 'footer.information.rates',
      },
      {
        href: '/payment-methods',
        translate: 'footer.information.paymentMethods',
      },
      {
        href: '/restrictions',
        translate: 'footer.information.restrictions',
      },
      {
        href: '/customs',
        translate: 'footer.information.customs',
      },
    ],
  },
  {
    title: 'footer.business.title',
    links: [
      {
        href: '/contacts',
        translate: 'footer.business.contacts',
      },
      {
        href: '/api-docs',
        translate: 'footer.business.api',
      },
    ],
  },
  {
    title: 'footer.aboutCompany.title',
    links: [
      {
        href: '/about',
        translate: 'footer.aboutCompany.aboutUs',
      },
      {
        href: '/terms',
        translate: 'footer.aboutCompany.termsAndConditions',
      },
      {
        href: '/privacy',
        translate: 'footer.aboutCompany.personalDataProtection',
      },
    ],
  },
];
