/* eslint-disable react/no-danger */
import React from 'react';

export const SEOMetaTags: React.FC = () => (
  <>
    <meta
      name="description"
      content="Хочешь обрадовать своих родных и отправить посылку из стран Европы? Теперь это легко! Заказывай отправку через удобный онлайн сервис SMART Post Global, а мы организуем доставку!"
    />
    <meta
      name="keywords"
      content="посылка, отправить посылку, Англия, Италия, Греция, Германия, Кипр, Польша, Испания, Португалия, Украина, Россия, Франция, США, посредник, smartpost, smart post, mail forwarding, доставка посылок, доставка товаров, доставка покупок, покупки, шоппинг, шопинг, shopping"
    />
    <meta property="og:site_name" content="SMART Post Global" />
    <meta property="og:url" content="https://smartpost.global/" />
    <meta property="og:title" content="SMART Post Global – доставка посылок из Европы" />
    <meta
      property="og:description"
      content="Хочешь обрадовать своих родных и отправить посылку из стран Европы? Теперь это легко! Заказывай отправку через удобный онлайн сервис SMART Post Global, а мы организуем доставку!"
    />
    <meta property="og:video" content="https://www.youtube.com/watch?v=P9tQlt3_A1Q" />
    <meta property="og:locale" content="ru_RU" />
    <meta property="og:locale:alternate" content="en_US" />
    <meta property="fb:app_id" content="107424427266415" />
    <meta property="og:image" content="https://media.smartpost.global/facebook.png" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:type" content="article" />
    <meta property="article:section" content="website" />
    <meta property="article:tag" content="smart forwarding" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@SMARTPostGlobal" />
    <meta name="twitter:domain" content="https://www.twitter.com/SMARTPostGlobal" />
    <meta name="twitter:title" content="SMART Post Global – доставка посылок из Европы" />
    <meta
      name="twitter:description"
      content="Хочешь обрадовать своих родных и отправить посылку из стран Европы? Теперь это легко! Заказывай отправку через удобный онлайн сервис SMART Post Global, а мы организуем доставку!"
    />
    <meta name="twitter:image:src" content="https://media.smartpost.global/twitter.png" />

    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `{
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "SMART Post Global",
            "url": "https://smartpost.global",
            "sameAs": [
            "https://www.twitter.com/SMARTPostGlobal",
            "https://www.facebook.com/smartpost.global"
            ]
          }`,
      }}
    />
  </>
);
