import styled from 'styled-components';

import { Dropdown } from '../element';

export const Item = styled(Dropdown.Item)`
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    width: 20px;
    margin-right: 5px;
  }
`;

export const Active = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  & img {
    width: 20px;
    margin-right: 5px;
  }
`;
