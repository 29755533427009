import { NavItemProps } from './nav';

export const links: NavItemProps[] = [
  {
    href: '/about',
    translate: 'header.aboutUs',
  },
  {
    href: '/rates',
    translate: 'header.rates',
  },
  {
    href: '/contacts',
    translate: 'header.contacts',
  },
  {
    translate: 'header.service.title',
    dropdown: {
      title: '',
      list: [
        {
          title: 'header.service.shippingTools',
          section: true,
          icon: 'el-icon-box',
        },
        {
          title: 'header.service.labels',
          href: '/tools/labels',
        },
        {
          title: 'header.service.noPrinter',
          href: '/tools/no-printer',
        },
        {
          title: 'header.service.tracking',
          href: '/tracking',
        },
        {
          title: 'header.service.volumeCalculator',
          href: '/tools/volume-calculator',
        },
        {
          title: 'header.service.customsDeclarations',
          href: '/tools/customs-declarations',
        },
        {
          title: 'header.service.information',
          section: true,
          icon: 'el-icon-document',
        },
        {
          title: 'header.service.packaging',
          href: '/packaging',
        },
      ],
    },
  },
];
