import React from 'react';
import styled from 'styled-components';

import { Link } from '../../i18n';
import { Dropdown } from '../element';

import { PartialTranslate } from '../../types/translate';
import { DropDownOption } from './types';

export interface DropDownProps {
  list: DropDownOption[];
  title: string;
}

const DropDownItem = styled(Dropdown.Item)`
  ${({ disabled, theme }) => disabled && `font-weight: bold; color: ${theme.colors.primary} !important`}
`;

const Anchor = styled.a`
  display: block;
  height: 100%;
`;

export const DropDown: React.FC<DropDownProps & PartialTranslate> = ({ t, title, list }) => {
  const menu = (
    <Dropdown.Menu>
      {list.map((item, idx) => (
        <DropDownItem key={idx.toString()} disabled={item.section}>
          <>
            {item.href && (
              <Link href={item.href}>
                <Anchor>
                  {item.icon && <i className={item.icon} />} {t(item.title)}
                </Anchor>
              </Link>
            )}

            {!item.href && (
              <span>
                {item.icon && <i className={item.icon} />} {t(item.title)}
              </span>
            )}
          </>
        </DropDownItem>
      ))}
    </Dropdown.Menu>
  );

  return (
    <Dropdown trigger="click" menu={menu}>
      <span className="el-dropdown-link">
        {t(title)} <i className="el-icon-arrow-down el-icon--right" />
      </span>
    </Dropdown>
  );
};
