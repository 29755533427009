import React from 'react';
import { useSelector } from 'react-redux';

import { isLoading } from '../../entities/app/selectors';

import { Loading } from '../element';
import { LoadingIcon } from './icon';

export const LoadingIndicator: React.FC = () => {
  const loading = useSelector(isLoading);

  return loading ? (
    <Loading loading fullscreen>
      <LoadingIcon />
    </Loading>
  ) : null;
};
