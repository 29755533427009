import styled from 'styled-components';

export const FooterBlock = styled.footer`
  margin-top: 65px;
  color: #6c6c6c;
  text-align: center;
`;

export const FooterSectionList = styled.ul`
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  list-style-type: none;

  & li:first-child {
    text-transform: uppercase;
    padding-bottom: 11px;
    font-weight: bolder;
    color: #292929;
    cursor: default;
  }

  & li .social {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
  }

  & li .social a:last-child {
    margin-right: 0;
  }
  & li .social a:hover {
    opacity: 1;
  }
`;
