import Router from 'next/router';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation, i18n } from '../../../i18n';
import { getCalculatorParams } from '../../../entities/providers/selectors';

import { Button } from '../../element';

const toRatesPage = async (params?: Record<string, string | number>): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { slug = '', ...query } = params || ({} as any);

  if (typeof window === 'object') {
    const qs = new URLSearchParams(query);
    const stringified = qs.toString();

    const lang = i18n.language;

    const path = `/${lang}/rates${slug}${stringified && `?${stringified}`}`;

    Router.push(path).then();
  }
};

export const SendParcel: React.FC<{ withParams?: boolean }> = ({ withParams = false }) => {
  const [t] = useTranslation('common');
  const params = useSelector(getCalculatorParams);

  const handleClick = useCallback(async () => {
    await toRatesPage(withParams ? params : undefined);
  }, [withParams, params]);

  return (
    <Button type="primary" nativeType="button" onClick={handleClick}>
      {t('header.btn')}
    </Button>
  );
};
