import React from 'react';

import { FooterBlock } from './styled';

import { Info } from './footer-info';
import { FooterBottom } from './footer-bottom';
import { FooterPayments } from './footer-payment';

export const Footer: React.FC = () => (
  <FooterBlock className="wrapper">
    <Info />
    <FooterBottom />
    <FooterPayments />
  </FooterBlock>
);
