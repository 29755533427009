import React from 'react';
import styled from 'styled-components';

import { Layout } from '../element';

const Row = styled(Layout.Row)`
  text-align: center;
  margin: 20px 0;
`;

// current year
const YEAR = Math.max(2020, new Date().getFullYear());

export const FooterBottom: React.FC = () => (
  <Row>
    <Layout.Col span={24}>
      Part of the SMART Forwarding SMPC <br />© SMART Post Global {YEAR}. All Rights Reserved
    </Layout.Col>
  </Row>
);
