import React from 'react';
import styled from 'styled-components';

import { useTranslation } from '../../i18n';
import { Layout } from '../element';

const Row = styled(Layout.Row)`
  & img {
    width: 40px;
  }

  & .label {
    margin-right: 10px;
  }
`;

export const FooterPayments = () => {
  const [, i18n] = useTranslation();

  const link3DSecure =
    i18n.language === 'el'
      ? 'https://paycenter.piraeusbank.gr/redirection/Content/HTML/3DSecure_el.html'
      : 'https://paycenter.piraeusbank.gr/redirection/Content/HTML/3DSecure_en.html';

  return (
    <Row>
      <Layout.Col span={24}>
        <img src="/static/img/bank/Visa.jpg" alt="visa" className="label" />
        <img src="/static/img/bank/Mastercard.jpg" alt="Mastercard" className="label" />
        <img src="/static/img/bank/Maestro.jpg" alt="Maestro" className="label" />
        <a href={link3DSecure} target="_blank" rel="noreferrer noopener">
          <img src="/static/img/bank/vbv.jpg" alt="Verified by VISA" className="label" />
        </a>
        <a href={link3DSecure} target="_blank" rel="noreferrer noopener">
          <img src="/static/img/bank/sc_68x37.gif" alt="MasterCard SecureCode" className="label" />
        </a>
        <img src="/static/img/bank/PB_blue_GR.png" alt="PB_blue_GR" className="label" />
      </Layout.Col>
    </Row>
  );
};
