import React from 'react';
import styled from 'styled-components';

const Btn = styled.button`
  height: ${({ theme }) => theme.sizes.header.height};

  padding: 15px 0 0;

  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent !important;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover,
  &:focus {
    opacity: 0.7;
    outline: none;
  }
`;

const Box = styled.span`
  width: 29px;
  height: 18px;

  display: block;
  position: relative;
  transform: scale(0.7);
  transform-origin: top left;

  & span {
    position: absolute;
    left: 0;

    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
  }
`;

const Inner = styled.span`
  background-color: #292929;
  transition: transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.15s, background-color 0s linear 75ms;

  & span:first-of-type {
    top: -10px;
    transition: top 75ms ease-in 75ms, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  }

  & span:last-of-type {
    bottom: -10px;
    transition: bottom 75ms ease-in 75ms, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  }

  &.active {
    transform: rotate(90deg);
    background-color: transparent !important;
  }

  &.active span:first-of-type {
    top: 0;
    transform: rotate(-45deg);
  }

  &.active span:last-of-type {
    bottom: 0;
    transform: rotate(45deg);
  }
`;

export const BurgerMenu: React.FC<{ isOpen: boolean; onClick(): void }> = ({ isOpen, onClick }) => (
  <Btn type="button" onClick={onClick}>
    <Box>
      <Inner className={isOpen ? 'active' : ''}>
        <span />
        <span />
      </Inner>
    </Box>
  </Btn>
);
