import React from 'react';
import classNames from 'classnames';

export interface ElementLinkProps {
  href: string;
  target?: string;
  type?: 'primary' | 'danger' | 'success' | 'warning' | 'info';
  icon?: string;
  className?: string;
  disabled?: boolean;
  underline?: boolean;
}

export const ElementLink: React.FC<ElementLinkProps> = (props) => {
  const { href, children, icon, className, type, target, disabled = false, underline = false } = props;

  return (
    <a
      href={href}
      target={target}
      className={classNames('el-link', className, type ? `el-link--${type}` : '', {
        'is-disabled': disabled,
        'is-underline': underline && !disabled,
      })}
    >
      {icon && <i className={icon} />}
      {typeof children === 'string' ? <span className="el-link--inner">{children}</span> : children}
    </a>
  );
};
