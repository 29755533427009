import React, { useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

// @ts-ignore
import OutsideClickHandler from 'react-outside-click-handler';

import { Layout } from '../element';
import { Logo } from '../logo';
import { Switcher } from '../lang-switcher';
import { BurgerMenu } from './burger-menu';

import { HeaderNav, MobileLogoContainer, MobileMenu, NavBar, NavLi } from './styled';
import { NavItem } from './nav';
import { links } from './links';
import { SendParcel } from '../anchor/send-parcel';

export const Header: React.FC = () => {
  const [hideOnScroll, setHideOnScroll] = useState(true);
  const [isOpen, toggle] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > 0 || currPos.y > prevPos.y;

      if (isShow !== hideOnScroll) {
        setHideOnScroll(isShow);
      }
    },
    [hideOnScroll]
  );

  return (
    <OutsideClickHandler onOutsideClick={() => toggle(false)}>
      <NavBar pinned={hideOnScroll}>
        <div className="wrapper">
          <Layout.Row gutter={20} type="flex" justify="space-between" align="middle" className="hidden-sm-and-down">
            <Layout.Col span={4}>
              <Logo />
            </Layout.Col>

            <Layout.Col span={18}>
              <HeaderNav>
                {links.map((link) => (
                  <NavItem key={link.translate} {...link} />
                ))}

                <NavLi>
                  <SendParcel />
                </NavLi>
              </HeaderNav>
            </Layout.Col>

            <Layout.Col span={2}>
              <Switcher />
            </Layout.Col>
          </Layout.Row>

          <Layout.Row gutter={20} type="flex" justify="space-between" align="middle" className="hidden-md-and-up">
            <Layout.Col span={4}>
              <BurgerMenu isOpen={isOpen} onClick={() => toggle(!isOpen)} />
            </Layout.Col>
            <Layout.Col span={16}>
              <MobileLogoContainer>
                <Logo />
              </MobileLogoContainer>
            </Layout.Col>
            <Layout.Col span={4}>
              <Switcher mobile />
            </Layout.Col>
          </Layout.Row>

          {isOpen && (
            <Layout.Row gutter={20} className="hidden-md-and-up">
              <Layout.Col span={24} sm={24}>
                <MobileMenu>
                  {links.map((link) => (
                    <NavItem key={link.translate} {...link} />
                  ))}
                </MobileMenu>
              </Layout.Col>
            </Layout.Row>
          )}
        </div>
      </NavBar>
    </OutsideClickHandler>
  );
};
