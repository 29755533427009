export const languages: Record<string, { flag: string }> = Object.freeze({
  en: {
    flag: '/static/img/flags/en.svg',
  },
  ru: {
    flag: '/static/img/flags/ru.svg',
  },
  ua: {
    flag: '/static/img/flags/ua.svg',
  },
  el: {
    flag: '/static/img/flags/el.svg',
  },
});

export const getLanguage = (code: string) => ({
  ...languages[code],
  code,
});

export const langList = Object.keys(languages).map(getLanguage);
